import { EmailIcon, InfoIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  ChakraProvider,
  Flex,
  Heading,
  Icon,
  Link,
  Text,
  Box,
  Image,
  Center,
} from "@chakra-ui/react";
import customTheme from "./custumTheme"; // Import your custom theme

function Contacts() {
  return (
    <ChakraProvider theme={customTheme}>
      <Center>
        <Box w="300px" h="100px" marginBottom="10px">
          <Link href="/">
            <Image src={"/Logo4new.svg"} />
          </Link>
        </Box>
      </Center>

      <Flex flexDirection="column" alignItems="center" justifyContent="center">
        <Heading fontSize="2xl" color="orange.500">
          Contact Us
        </Heading>
        <Flex
          flexDirection="row"
          alignItems="center"
          m={2}
          fontWeight="bold"
          fontSize="lg"
        >
          <Icon as={EmailIcon} boxSize={6} mr={2} color="orange.500" />
          <Link
            href="mailto:Order@sl-shipping.eu"
            _hover={{
              textDecoration: "none",
            }}
            fontSize="md"
            color="orange.500"
          >
            order@sl-shipping.eu
          </Link>
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          m={2}
          fontWeight="bold"
          fontSize="lg"
        >
          <Icon as={PhoneIcon} boxSize={6} mr={2} color="orange.500" />
          <Text fontSize="md">+372 5599 7663</Text>
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          m={2}
          fontWeight="bold"
          fontSize="lg"
        >
          <Icon as={InfoIcon} boxSize={6} mr={2} color="orange.500" />
          <Text fontSize="md">Building D, 10 Turi 11313, Tallinn, ESTONIA</Text>
        </Flex>

        {/* Embed OpenStreetMap */}
        <iframe
          width="80%"
          height="500"
          src={`https://www.openstreetmap.org/export/embed.html?bbox=24.7068%2C59.4357%2C24.7178%2C59.4405&marker=59.4381,24.7123&zoom=17`}
          style={{ border: "1px solid black" }}
        ></iframe>
        <br />
        <small>
          <a href="https://www.openstreetmap.org/?mlat=59.4381&mlon=24.7123#map=16/59.4381/24.7123">
            View Larger Map
          </a>
        </small>
      </Flex>
    </ChakraProvider>
  );
}

export default Contacts;
