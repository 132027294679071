import { EmailIcon, InfoIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  ChakraProvider,
  Flex,
  Icon,
  Box,
  Text,
  Heading,
  Link,
} from "@chakra-ui/react";

function Footer() {
  return (
    <ChakraProvider>
      <Box
        as="footer"
        position="absolute"
        bottom="0"
        w="100%"
        bgGradient="linear(to-t, orange.300, orange.200 )"
        p={4}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          fontWeight="bold"
        >
          <Heading
            fontSize="xl"
            fontWeight="extrabold"
            color="orange.500"
            mb={2}
          >
            SL Shipping Embedded Devices
          </Heading>
          <Flex flexDirection="row" alignItems="center" justifyContent="center">
            <Icon mx={2} as={EmailIcon} boxSize={6} color="orange.500" />
            <Link
              href="mailto:Order@sl-shipping.eu"
              _hover={{
                textDecoration: "none",
              }}
              fontSize="md"
              color="orange.500"
            >
              order@sl-shipping.eu
            </Link>
          </Flex>

          <Flex alignItems="center" flexDirection="row" fontSize="lg" mt={2}>
            <Icon as={PhoneIcon} boxSize={6} color="orange.500" mr={2} />
            <Text fontSize="md">+372 5599 7663</Text>
          </Flex>
          <Flex alignItems="center" fontSize="lg" mt={2}>
            <Icon as={InfoIcon} boxSize={6} color="orange.500" mr={2} />
            <Text fontSize="md">
              Building D, 10 Turi 11313, Tallinn, ESTONIA
            </Text>
          </Flex>
        </Flex>
      </Box>
    </ChakraProvider>
  );
}

export default Footer;
