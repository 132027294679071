import * as React from "react";
import {
  Box,
  Center,
  ChakraProvider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Image,
  ListItem,
  UnorderedList,
  Spacer,
  Highlight,
  Link,
  Card,
  CardBody,
  Divider,
  Stack,
  SimpleGrid,
} from "@chakra-ui/react";
import { EmailIcon, InfoIcon, PhoneIcon } from "@chakra-ui/icons";
import { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Contacts from "./Contacts";

export function Home() {
  return (
    <ChakraProvider>
      <Center w="100%">
        <Flex
          flexDirection="column"
          w="100%"
          h="100%"
          bg="whiteAlpha.800"
          fontFamily="system-ui"
          fontSize="18px"
          marginBottom={10}
        >
          <Box w="100%" h="100%" justifyContent="center">
            <Header />
            <Flex flexDirection="row" justifyContent="space-between" w="85%" position="absolute" left="100px" top="120px">
              
                <Link
                marginRight={10}
    
                  _hover={{
                    textDecoration: "none",
                    borderBottom: "3px solid orange",
                    transition: "transform 0.3s ease-in-out",
                  }}
                  href="#1"
                  fontWeight="extrabold"
                  color="whiteAlpha.800"
                  fontSize="18px"
                >
                  Electronic Design Service
                </Link>
                <Link
                  mx={10}
                  _hover={{
                    textDecoration: "none",
                    borderBottom: "3px solid orange",
                    transition: "transform 0.3s ease-in-out",
                  }}
                  href="#2"
                  fontWeight="extrabold"
                  color="whiteAlpha.800"
                  fontSize="18px"
                >
                  What is Hardware Development
                </Link>
                <Link
                  mx={10}
                  _hover={{
                    textDecoration: "none",
                    borderBottom: "3px solid orange",
                    transition: "transform 0.3s ease-in-out",
                  }}
                  href="#3"
                  fontWeight="extrabold"
                  color="whiteAlpha.800"
                  fontSize="18px"
                >
                  Designing Electronics
                </Link>
                <Link
                  mx={10}
                  _hover={{
                    textDecoration: "none",
                    borderBottom: "3px solid orange",
                    transition: "transform 0.3s ease-in-out",
                  }}
                  href="#4"
                  fontWeight="extrabold"
                  color="whiteAlpha.800"
                  fontSize="18px"
                >
                  Services
                </Link>
                <Link
                  mx={10}
                  _hover={{
                    textDecoration: "none",
                    borderBottom: "3px solid orange",
                    transition: "transform 0.3s ease-in-out",
                  }}
                  href="/contacts"
                  fontWeight="extrabold"
                  color="whiteAlpha.800"
                  fontSize="18px"
                >
                  Contacts
                </Link>
                
            
            </Flex>
          </Box>
          <Center marginBottom="300px">
            <Box w="80%" m={4}>
              <Heading marginBottom={10} marginTop={10}>
                <Highlight
                  query="Looking for a reliable partner for electronics development?"
                  styles={{
                    px: "2",
                    py: "1",
                    rounded: "full",
                    bg: "orange.200",
                  }}
                >
                  Looking for a reliable partner for electronics development?
                </Highlight>
              </Heading>

              <Flex flexDirection="row" alignContent="space-between">
                {/* Left side with text */}
                <Flex flexDirection="column" w="35%" marginRight="120px">
                  <Text m={2}>
                    Electronics, embedded systems, digital devices, algorithms
                    and software development.
                  </Text>
                  <Text m={2}>
                    Solutions and devices developed by our teams are now in
                    operation around the world
                  </Text>
                </Flex>

                {/* Right side with two rows */}
                <Box>
                  <Flex flexDirection="row">
                    <Flex flexDirection="column" w="50%">
                      {/* First column */}
                      <Flex flexDirection="column" alignItems="center" m={5}>
                        <Image src="\ico1-48x48-023 1.png" w="48px" h="48px" />
                        <Heading fontSize="16px">Confidentiality</Heading>
                        <Text fontSize="14px" textAlign="center">
                          we protect customer's intellectual property
                        </Text>
                      </Flex>

                      {/* Second column */}
                      <Flex flexDirection="column" alignItems="center" m={5}>
                        <Image src="\ico2-48x48-023 2.png" w="48px" h="48px" />
                        <Heading fontSize="16px">Comprehension</Heading>
                        <Text fontSize="14px" textAlign="center">
                          we understand the needs of the customer
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex flexDirection="column" w="50%">
                      {/* Third column */}
                      <Flex flexDirection="column" alignItems="center" m={5}>
                        <Image src="\ico3-48x48-023 3.png" w="48px" h="48px" />
                        <Heading fontSize="16px">
                          Competence and quality
                        </Heading>
                        <Text fontSize="14px" textAlign="center">
                          in everything we do
                        </Text>
                      </Flex>

                      {/* Fourth column */}
                      <Flex flexDirection="column" alignItems="center" m={5}>
                        <Image src="\ico4-48x48-023 4.png" w="48px" h="48px" />
                        <Heading fontSize="16px">Enthusiasm</Heading>
                        <Text fontSize="14px" textAlign="center">
                          we are fully committed to our work
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex flexDirection="column" w="50%">
                      {/* Fifth column */}
                      <Flex flexDirection="column" alignItems="center" m={5}>
                        <Image src="\ico5-48x48-023 5.png" w="48px" h="48px" />
                        <Heading fontSize="16px">Trust</Heading>
                        <Text fontSize="14px" textAlign="center">
                          in building long-term relationships
                        </Text>
                      </Flex>

                      {/* Sixth column */}
                      <Flex flexDirection="column" alignItems="center" m={5}>
                        <Image src="\ico6-48x48-023 6.png" w="48px" h="48px" />
                        <Heading fontSize="16px">Openness and decency</Heading>
                        <Text fontSize="14px" textAlign="center">
                          in work and in cooperation
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>

              <Heading marginBottom={10} marginTop={10} id="1">
                <Highlight
                  query="Electronic Design Service"
                  styles={{
                    px: "2",
                    py: "1",
                    rounded: "full",
                    bg: "orange.200",
                  }}
                >
                  Electronic Design Service
                </Highlight>
              </Heading>
              <Text my={2}>
                SL Shipping Embedded Devices - hardware development company
                which provides electronic design service. We develop
                electronics, embedded solutions and digital devices in a wide
                range of fields since 2014. Founded in Estonia, we provide
                different electronic product design and hardware development
                solutions such as:
              </Text>
              <UnorderedList my={4} spacing={3}>
                <ListItem>Video Processing Software;</ListItem>
                <ListItem>Optical Design Device;</ListItem>
                <ListItem>Wearable Device Development;</ListItem>
                <ListItem>Embedded System Hardware;</ListItem>
                <ListItem>Special Device Design;</ListItem>
                <ListItem>Single Board Computers.</ListItem>
              </UnorderedList>
              <Text my={2}>
                Our systems are used all around the world. We developed such
                things as: metrology equipment, night and thermal vision optics,
                machine vision, 2D and 3D scanning, consumer electronics,
                accounting systems and monitoring at gas distribution stations,
                protected computers, industrial controllers, technical processes
                automation systems, nuclear energy control systems and the list
                goes on.
              </Text>
              <Text my={2}>
                We believe that with competence and high quality standards in
                everything we do, enthusiasm of our specialists and our desire
                to understand every tiny detail of every customer’s future
                project, we can bring your vision to life.{" "}
              </Text>

              <Text my={2}>
                {" "}
                But first we want to tell you about our job. How we see hardware
                development and electronic system design and show you how we
                create things.
              </Text>
              <Heading marginBottom={10} marginTop={20} id="2">
                <Highlight
                  query="What is Hardware Development"
                  styles={{
                    px: "2",
                    py: "1",
                    rounded: "full",
                    bg: "orange.200",
                  }}
                >
                  What is Hardware Development
                </Highlight>
              </Heading>
              <Text my={2}>
                {" "}
                So what is the meaning of hardware design and hardware
                development? By “hardware” we usually name electrical or
                electro-mechanical devices, which may contain embedded software.
                Think of phones, computers, medical tools, networking equipment
                and other communications technology and so on. “Hardware design”
                basically mean the development of designs for devices that are
                meant to be manufactured.
              </Text>
              <Text my={2}>
                {" "}
                Believe it or not, it is really similar to software development
                process. You have to think of User Experience - how users will
                interact with your product, how your product will interact with
                other products, what is your product’s functional and
                non-functional requirements and so on.
              </Text>
              <Text my={2}>
                {" "}
                What is different in designing hardware from designing software
                is the difficulty of changes. It’s pretty simple in software -
                you gotta rewrite a code, but hardware? Changes mean creating
                new physical version of hardware which cost much higher.
                Luckily, our specialists have many years of experience in
                creating really accurate and precise hardware designs and
                prototypes.
              </Text>
              <Text my={2}>
                {" "}
                Another thing that is different from soft - no real-time
                updates. Any operating system updates frequently while every new
                device (phone, laptop, tablet or desktop) release once a year or
                two. Think of that difference.
              </Text>
              <Text my={2}>
                {" "}
                What is also different is testing and releasing hardware
                products. You have to make sure that your device will work over
                a long period of time and in different environmental conditions.
                As for shipping the product, it is sending it to local
                distributors or shops which is also way more difficult than
                uploading an app to a digital store.
              </Text>

              <Heading marginBottom={10} marginTop={20} id="3">
                <Highlight
                  query="Designing Electronics"
                  styles={{
                    px: "2",
                    py: "1",
                    rounded: "full",
                    bg: "orange.200",
                  }}
                >
                  Designing Electronics
                </Highlight>
              </Heading>

              <Text my={2}>
                Designing electronic systems starts with basic electronic
                development.
              </Text>
              <Text my={2}>
                It has a lot in common with hardware development. Electronic
                designer has to think of what features the product will have,
                how users will interact with it, how much will it cost to
                develop such products and so on.
              </Text>
              <Text my={2}>
                Let’s take a look at each stage of hardware and electronic
                hardware design.
              </Text>
              <Text mt="6">
                <Highlight
                  query="Estimation stage."
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Estimation stage. It all starts with gathering as much
                  information as possible about the desired product. We get
                  technical requirements from the customer with a defined
                  primary function that the device must perform and define the
                  budget of the project.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query="Preparation stage."
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Preparation stage. After estimation stage we purchase all
                  necessary components, as well as evaluation kits to produce
                  prototype. We also develop test programs and measure technical
                  parameters according to the list of technical characteristics
                  and requirements.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query="Prototyping stage."
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Prototyping stage. The next step is creating a prototype for
                  further tests. This stage include PCB design schematic, PCB
                  design, software development and prototyping the product.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query="Hardware platform design stage."
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Hardware platform design stage. This one already sound
                  familiar, right? The stage consist preparation of the hardware
                  and software integrated solution and its modules for
                  manufacturing - thermal modeling, 3D modeling, coordination
                  with the requirements of a specific manufacturer, preparation
                  of a package of design and software documentation.
                </Highlight>
              </Text>
              <Text my={2}>
                Installation of electronic components - manual installation,
                installation on an automatic line, preparation of documentation
                for installation, BGA mounting, FLIP-Chip, CSP, QFP, SMD.
              </Text>
              <Text mt="6">
                <Highlight
                  query="Embedded software development stage."
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Embedded software development stage. On this stage of hardware
                  and software development we create the software for your
                  custom electronic products. It may be something simple or
                  complex, depending on technical requirements. We also design
                  tests for functional testing, production test software, user
                  interfaces, mobile application - whatever your product needs.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query="Electronics rapid prototyping stage."
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Electronics rapid prototyping stage. Our specialists run
                  components procurement process, printed circuit boards
                  manufacturing and assembly, perform assembly of the custom
                  made electronics, test the assembled device, solution
                  prototype. As a result of this stage, the Customer is shipped
                  working prototypes.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query="Testing stage."
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Testing stage. We run multiple tests of your product,
                  implement changes and adjustments both to hardware and
                  software.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query="Preparation of production stage."
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Preparation of production stage. After all tests are done and
                  we have a working prototype, the product is then mass
                  produced. We also adapt the design and software documentation
                  for a specific manufacturer and particular constraints of this
                  or that manufacturer, test methods and procedures development
                  or adjustment. We can also attach specialized test hardware
                  solutions development and production if necessary.
                </Highlight>
              </Text>

              <Heading marginBottom={10} marginTop={20} id="4">
                <Highlight
                  query="Services"
                  styles={{
                    px: "2",
                    py: "1",
                    rounded: "full",
                    bg: "orange.200",
                  }}
                >
                  Services
                </Highlight>
              </Heading>

              <SimpleGrid columns={[2, null, 3]} spacing="40px">
                <Box>
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/razrabotka1-350x180-da7 .jpg"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">
                          Development: Project Estimation Stage
                        </Heading>
                        <Text>
                          Project estimation, technical research, technical and
                          commercial offer.
                        </Text>
                      </Stack>
                    </CardBody>
                  </Card>
                </Box>
                <Box>
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/components-350x180-3ee_small-350x180-554.jpg"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">
                          Development: Project Preparation Stage
                        </Heading>
                        <Text>
                          Development project preparation stage - technical
                          specification, delivery-acceptance procedure, scope of
                          works, calendar plan, budget.
                        </Text>
                      </Stack>
                    </CardBody>
                    
                  </Card>
                </Box>
                <Box>
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/circuit-board_2531458-350x180-842.jpg"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">
                          Development: Project Operational Stage
                        </Heading>
                        <Text>
                          New product, digital devices, embedded solutions and
                          electronics development project operational stage.
                        </Text>
                      </Stack>
                    </CardBody>
                    
                  </Card>
                </Box>
                <Box>
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/pcb-small-350x180-fa1.jpg"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">Hardware Platform Design</Heading>
                        <Text>
                          Hardware platform and solutions development
                          engineering services.
                        </Text>
                      </Stack>
                    </CardBody>
                    
                  </Card>
                </Box>
                <Box>
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/chip-on-circuit-board-on-abstract-technology-background_1387-1013-350x180-eb4.jpg"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">
                          Embedded Software Development
                        </Heading>
                        <Text>
                          Embedded software development outsourced engineering
                          services. One-stop embedded software design.
                        </Text>
                      </Stack>
                    </CardBody>
                    
                  </Card>
                </Box>
                <Box>
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/software-development-350x180-a9e.jpg"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">Custom Software Development</Heading>
                        <Text>
                          Custom software one-stop development services.
                        </Text>
                      </Stack>
                    </CardBody>
                    
                  </Card>
                </Box>
                <Box>
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/computer-technician-ready-to-start-to-work_1161-90-350x180-cb5.jpg"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">
                          Electronics Rapid Prototyping
                        </Heading>
                        <Text>
                          Electronics rapid prototyping, prototypes testing,
                          prototypes manufacturing.
                        </Text>
                      </Stack>
                    </CardBody>
                    
                  </Card>
                </Box>
                <Box>
                  {" "}
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/future-abstract-technology-background_1406-110-350x180-9ae.jpg"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">
                          Development: Project Final Stage
                        </Heading>
                        <Text>
                          Electronics design project final stage - design and
                          software documentation, test and functional software,
                          prototypes, test protocols, delivery-acceptance
                          certificate.
                        </Text>
                      </Stack>
                    </CardBody>
                    
                  </Card>
                </Box>
                <Box>
                  <Card w="sm" m={2} h="400px">
                    <CardBody>
                      <Image
                        w="350px"
                        h="180px"
                        src="/electronics-production-350x180-fac.png"
                        alt="razrabotka"
                        borderRadius="md"
                      />
                      <Stack mt="6" spacing="3">
                        <Heading size="md">
                          Preparation of Batch Production
                        </Heading>
                        <Text>
                          Preparation of batch production engineering services.
                          Ready-to-manufacture electronics, wearable devices,
                          medical equipment, optical devices..
                        </Text>
                      </Stack>
                    </CardBody>
                
                  </Card>
                </Box>
              </SimpleGrid>

              <Text mt="6">
                <Highlight
                  query=" Preliminary assessment"
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  Contract development of digital devices, electronics and
                  embedded solutions includes a number of basic stages.
                  Preliminary assessment of the Customer's requirements to the
                  future technical system as a step allows to work out the
                  technical requirements provided by the Customer, to test the
                  feasibility of implementing such a solution, to prepare a
                  preliminary version of the architecture of the technical
                  system being developed, to verify the feasibility of
                  requirements and characteristics, to consider the testing and
                  verification tools of the planned technical solution, project
                  development and the cost of these works.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query="The preparation stage"
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  The preparation stage includes the elaboration of the
                  technical task with specification of the hardware solution
                  architecture and the software of the projected solution,
                  specification of the characteristics, testing and measuring
                  facilities and procedures, the composition of the project team
                  and the work schedule, the cost of works and prototyping, the
                  order of acceptance of the development results and the
                  contract for development of a technical system.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query={[
                    "The operational stage",
                    "hardware development",
                    "software development:",
                  ]}
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  The operational stage - development itself - consists of the
                  hardware development: schematic design, printed circuit boards
                  design and modeling, components search and verification,
                  prototype production; and software development: embedded
                  software design, SW modules with (and without) the operating
                  system, porting to the device, device drivers, design for
                  FPGA, various algorithms for data collecting, signals and data
                  processing, software modules testing; high-level software
                  modules development - user interface and mobile application
                  with various functions.
                </Highlight>
              </Text>
              <Text mt="6">
                <Highlight
                  query="the final stage"
                  styles={{ py: "1", fontWeight: "bold" }}
                >
                  At the final stage, the development team checks prototypes on
                  the compliance with the technical specifications of the
                  requirements, inputs changes and additions to the design and
                  programming documentation. Then we run ready-to-manufacture
                  works supporing our customers to launch their products and
                  devices in production, whether it is the production site of
                  the customer himself, or outsourced manufacturer.
                </Highlight>
              </Text>
            </Box>
          </Center>
          <Footer />
        </Flex>
      </Center>
    </ChakraProvider>
  );
}
export default Home;
