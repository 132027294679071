import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  colors: {
    orange: {
      50: "#FFF5E1",
      100: "#FFD8A8",
      200: "#FFBF70",
      300: "#FFA939",
      400: "#FF9202",
      500: "#E87700",
      600: "#B35C00",
      700: "#813D00",
      800: "#4F1E00",
      900: "#200100",
    },
  },
});

export default customTheme;
