import { ChevronLeftIcon, ChevronRightIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  ChakraProvider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

function Header() {
  const images = [
    "/contract-development-slider-1900x600-085.jpg",
    "/design-house1-1900x600-5f9.jpg",
    "/embedded-engineers-1900x600-15d.jpeg",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const changeImage = () => {
    // Increment the image index, and loop back to the first image when reaching the end
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const changeImageAutomatically = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };
  console.log(currentImageIndex);

  // Use useEffect to set up an interval for automatic image change
  useEffect(() => {
    const interval = setInterval(changeImageAutomatically, 20000); // Change image every 20 seconds (adjust as needed)

    return () => {
      clearInterval(interval); // Clean up the interval on component unmount
    };
  }, []);

  return (
    <ChakraProvider>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        w="100%"
        height="100vh"
      >
        <Image
          as="img"
          src={images[currentImageIndex]}
          minHeight="100%"
          objectFit="cover"
          minWidth="100%"
          background-repeat="no-repeat"
          style={{ filter: "brightness(45%)" }}
        />
        {currentImageIndex == 0 ? (
          <Box
            position="absolute"
            top="300px"
            left="100px"
            color="whiteAlpha.800"
            w="40%"
          >
            <Heading
              font-size="42px"
              font-weight="600"
              margin-bottom="24px"
              line-height="1.1"
            >
              SL - new product development
            </Heading>
            <Text fontSize="16px" my={4}>
              Contract development, prototyping and serial production of digital
              devices, consumer electronics for the mass market.
            </Text>
          </Box>
        ) : (
          <>
            {currentImageIndex == 1 ? (
              <Box
                position="absolute"
                top="300px"
                left="100px"
                color="whiteAlpha.800"
                w="40%"
              >
                <Heading
                  font-size="42px"
                  font-weight="600"
                  margin-bottom="24px"
                  line-height="1.1"
                >
                  SL - embedded solutions
                </Heading>
                <Text fontSize="16px" my={4}>
                  SL Shipping Embedded Devices embedded development services
                  include transitioning a product from technical requirements
                  documentation to prototype and to all scale production levels
                  manufacturing.{" "}
                </Text>
              </Box>
            ) : (
              <Box
                position="absolute"
                top="300px"
                left="100px"
                color="whiteAlpha.800"
                w="40%"
              >
                <Heading
                  font-size="42px"
                  font-weight="600"
                  margin-bottom="24px"
                  line-height="1.1"
                >
                  SL - one-stop electronics design house
                </Heading>{" "}
                <Text fontSize="16px" my={4}>
                  SL Shipping Embedded Devices develops electronics, embedded
                  solutions and digital devices in a wide range of fields. Our
                  engineers have the expertise to manage the design and get it
                  into prototype testing quickly.
                </Text>
              </Box>
            )}
          </>
        )}

        <ChevronLeftIcon
          as="button"
          onClick={changeImage}
          boxSize={10}
          position="absolute"
          top="350px"
          left="15px"
          color="whiteAlpha.700"
        />
        <ChevronRightIcon
          as="button"
          onClick={changeImage}
          boxSize={10}
          position="absolute"
          top="350px"
          right="15px"
          color="whiteAlpha.700"
        />
        <Flex flexDirection="row" justifyContent="space-between" w="85%"   position="absolute"
            top="20px" left="90px"> 
          <Box w="300px" h="100px" marginBottom="10px">
          <Link href="/">
            <Image src={"/Logo4new.svg"} />
          </Link>
        </Box>

          <Flex
            flexDirection="row"
            
            alignItems="center"
            m={2}
            fontWeight="extrabold"
            color="whiteAlpha.800"
          >
            {" "}
            <Icon m={2} as={EmailIcon} boxSize={6} />
            <Link
              _hover={{
                textDecoration: "none",
              }}
              href="mailto:Order@sl-shipping.eu"
              my={2}
            >
              order@sl-shipping.eu
            </Link>
          </Flex>
        </Flex>
       
      </Flex>
    </ChakraProvider>
  );
}

export default Header;
