import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Contacts from "./components/Contacts";


function App() {
  useEffect(() => {
    document.title = 'Sl-shipping';
    return () => {
      // Возвращаемся к стандартному заголовку при размонтировании компонента
      document.title = 'Sl-shipping';
    };}, []);

    useEffect(() => {
      // Get the favicon element
      const favicon = document.querySelector('link[rel="icon"]')as HTMLLinkElement;
      
      if (favicon) {
        // Change the favicon when the component mounts
        favicon.href = '/Logo4cut.ico';
  
        return () => {
          // Revert to the original favicon when the component unmounts
          favicon.href = '/Logo4cut.ico';
        };
      }
    }, []);
 
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/contacts" element={<Contacts/>}/>
          <Route path="/kontakti" element={<Contacts/>}/>
          <Route path="/kontaktid" element={<Contacts/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
